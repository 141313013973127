import React, { useState, useEffect, useRef, memo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import {
  IWorkshop,
  IMunicipalityZone,
  municipalityZones as storedMunicipalityZones,
} from "../../../models";
import { WorkshopAPI /* , CatalogAPI */ } from "../../../services";
import { getSasToken } from "../../../stores";
import OutstandingWorkshopCard from "../../../components/Cards/OutstandingWorkshopCard";
import OutstandingWorkshopCardSkeleton from "../../../components/Skeleton/OutstandingWorkshopCardSkeleton";
import { CkButtonGroup, CkButton } from "../../../CkUI";
import "./styles.css";

const defaultLocation = {
  label: "Todos",
  value: "",
};

interface IOutstandingWorkshopArea {
  label: string;
  value: string;
}

const OutstandingWorkshop = () => {
  /**
   * States
   */
  const carouselRef = useRef<CarouselRef>(null);
  const allowedZones = useRef<string[]>([]);
  const history = useHistory();
  const sasToken: string = useSelector(getSasToken);
  const [cityInfo, setCityInfo] = useState<{
    selectedArea: string;
    selectedLocation: string;
  }>({
    selectedArea: "",
    selectedLocation: "",
  });
  const [selectedAreaLabel, setSelectedAreaLabel] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [workshopsList, setWorkshopsList] = useState<Array<IWorkshop>>([]);
  const [generalAreasData, setGeneralAreasData] = useState<IMunicipalityZone[]>(
    []
  );
  const [generalAreas, setGeneralAreas] = useState<IOutstandingWorkshopArea[]>([
    defaultLocation,
  ]);
  const [municipalityZones, setMunicipalityZones] = useState<
    IOutstandingWorkshopArea[]
  >([]);
  const [forceHeight, setForceHeight] = useState<number>();
  const [forceHideSection, setForceHideSection] = useState<boolean>(false);

  /**
   * Functions
   */
  const getWorkshops = async () => {
    if (loading === false) setLoading(true);
    const requestData = await WorkshopAPI.getWorkshopHighlight(
      cityInfo.selectedArea,
      cityInfo.selectedLocation
    )
      .then((response) => response.data)
      .catch(() => []);
    setWorkshopsList(requestData);

    if (
      cityInfo.selectedArea === "" &&
      cityInfo.selectedLocation === "" &&
      requestData.length === 0
    ) {
      setForceHideSection(true);
      return;
    }

    if (
      generalAreasData.length === 0 &&
      cityInfo.selectedArea === "" &&
      cityInfo.selectedLocation === ""
    ) {
      const _allowedZones: string[] = [];
      requestData.forEach((data) => {
        if (
          !!data.location.cityZone &&
          _allowedZones.includes(data.location.cityZone) === false
        ) {
          _allowedZones.push(data.location.cityZone);
        }
      });
      allowedZones.current = _allowedZones;
      getMunicipalityZones();
    }
    setLoading(false);
  };

  const getMunicipalityZones = async () => {
    /* 
    const storedMunicipalityZones: IMunicipalityZone[] =
      await CatalogAPI.getMunicipalityZones()
        .then((response) => response.data)
        .catch(() => []); 
    */

    const areas = [
      defaultLocation,
      ...storedMunicipalityZones
        .map((area: IMunicipalityZone) => {
          return {
            label: area.cityDescription,
            value: area.cityCopomexName,
          };
        })
        .filter((area) => {
          return allowedZones.current.some((allowedZone) =>
            allowedZone.includes(area.value)
          );
        }),
    ];
    setGeneralAreasData(storedMunicipalityZones);
    setGeneralAreas(areas);
  };

  const updateHeight = () => {
    const height = carouselRef?.current?.innerSlider?.list?.clientHeight;
    if (forceHeight !== height && height !== undefined) setForceHeight(height > 565 ? 565 : height);
  };

  const setLocationsAndLabel = () => {
    setSelectedAreaLabel("");
    setMunicipalityZones([]);

    if (
      cityInfo.selectedArea === "" ||
      cityInfo.selectedArea === defaultLocation.label
    )
      return;

    const area = generalAreasData.find(
      (area) => area?.cityCopomexName === cityInfo.selectedArea
    );

    if (!area || area?.zones.length < 1) return;

    const result = area?.zones
      ?.map((zone: any) => {
        return {
          label: zone.replace("CDMX ", ""),
          value: zone,
        };
      })
      .filter((area) => {
        return allowedZones.current.some((allowedZone) =>
          allowedZone.includes(area.value)
        );
      });

    setSelectedAreaLabel(area?.cityDescription);
    setMunicipalityZones([...result]);
  };

  /**
   * Effects
   */
  useEffect(() => {
    setLocationsAndLabel();
    getWorkshops();
  }, [cityInfo]);

  /**
   * Components
   */
  if (forceHideSection) return null;

  return (
    <section className="outstanding-workshops">
      <div className="container">
        <h2>Talleres destacados</h2>
        <div className="buttons-container">
          <CkButtonGroup
            options={generalAreas}
            value={cityInfo.selectedArea}
            onClick={(newValue) =>
              setCityInfo({
                selectedArea: newValue.toString(),
                selectedLocation: "",
              })
            }
          />
        </div>
        {cityInfo.selectedArea !== "" &&
          cityInfo.selectedArea !== defaultLocation.label &&
          generalAreasData.filter(
            (area) => area.cityCopomexName === cityInfo.selectedArea
          )[0]?.zones.length > 1 &&
          municipalityZones.length > 0 && (
            <div className="buttons-container subset">
              <div className="municipality-zones-text">{selectedAreaLabel}</div>
              <div className="buttons-container">
                <CkButtonGroup
                  options={municipalityZones}
                  value={cityInfo.selectedLocation}
                  onClick={(newValue) =>
                    setCityInfo({
                      ...cityInfo,
                      selectedLocation: newValue.toString(),
                    })
                  }
                />
              </div>
            </div>
          )}
        <div className="carousel-container">
          {loading === false && (
            <CkButton
              icon="caret-left"
              className="navigation-arrow prev"
              type="link"
              shape="circle"
              onClick={() => {
                carouselRef.current && carouselRef.current.prev();
              }}
              aria-label="Talleres destacados previos"
            />
          )}
          <Carousel
            ref={carouselRef}
            dots={false}
            arrows={false}
            infinite={false}
            draggable
            swipeToSlide
            variableWidth
            centerPadding="10px"
            onInit={updateHeight}
            onReInit={updateHeight}
            slidesPerRow={1}
          >
            {loading !== false && <OutstandingWorkshopCardSkeleton />}
            {loading !== false && <OutstandingWorkshopCardSkeleton />}
            {loading !== false && <OutstandingWorkshopCardSkeleton />}
            {loading === false &&
              workshopsList.length > 0 &&
              workshopsList.map((workshop) => (
                <div>
                  {OutstandingWorkshopCard({
                    sasToken: sasToken,
                    workshop: workshop,
                    history: history,
                    forceHeight: forceHeight,
                  })}
                </div>
              ))}
          </Carousel>
          {loading === false && (
            <CkButton
              icon="caret-right"
              className="navigation-arrow next"
              type="link"
              shape="circle"
              onClick={() => {
                carouselRef.current && carouselRef.current.next();
              }}
              aria-label="Talleres destacados siguientes"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default memo(OutstandingWorkshop);
