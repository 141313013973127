import React, { memo } from "react";
import { Row, Col } from "antd";
import { CkIcon } from "../../../CkUI/components";
import "./styles.css";

const reasons = [
  "Checa el progreso de tu auto o motocicleta",
  "¡Disfruta de las promociones exclusivas de los talleres afiliados!",
  "Agenda tu servicio fácilmente",
  "Los talleres afiliados a CarKer cumplen con altos estándares de calidad y garantía.",
  "Revisa el historial de tus servicios",
];

const WhyUseIt = () => (
  <section className="why-use-it">
    <h2>¿Por qué usar CarKer?</h2>
    <Row className="reason-container">
      {reasons.map((reason, index) => (
        <Col key={`why-reason-${index}`} md={24} lg={12}>
          <div className="reason-item">
            <CkIcon name="checkmark-round" className="reason-icon" width="" height="" fill="" />
            <p>{reason}</p>
          </div>
        </Col>
      ))}
    </Row>
  </section>
);
export default memo(WhyUseIt);
