import React, { FC, memo } from "react";
import { CkIcon } from "../../../../CkUI";
import "./styles.css";

interface IProps {
  workshopAdditionalInfo: any[];
  isMobile: boolean;
}
const WorkshopAdditional: FC<IProps> = ({
  workshopAdditionalInfo,
  isMobile,
}) => {
  if (!!workshopAdditionalInfo === false || workshopAdditionalInfo.length === 0)
    return null;
  return (
      <div className="workshop-additional-container">
        <p className="workshop-label">Contamos con:</p>
        <div className="additional-images-container">
          {workshopAdditionalInfo.filter(
            (additional) => additional.code === "INFO0002"
          ).length > 0 && (
            <div className="characteristic-container">
              <CkIcon name="delivery" width="" height="" fill="" />
              <p className="additional-label">
                Recolección y entrega a domicilio
              </p>
            </div>
          )}
          {workshopAdditionalInfo.filter(
            (additional) => additional.code === "INFO0003"
          ).length > 0 && (
            <div className="characteristic-container">
              <CkIcon name="crane" width="" height="" fill="" />
              <p
                className="additional-label"
                style={isMobile ? {} : { width: "65px" }}
              >
                Servicio de grúa
              </p>
            </div>
          )}
          {workshopAdditionalInfo.filter((additional) => additional.code === "")
            .length > 0 && (
            <div className="characteristic-container">
              <CkIcon name="hand-payment" width="" height="" fill="" />
              <p
                className="additional-label"
                style={isMobile ? {} : { width: "100px" }}
              >
                Pago con Tarjeta
              </p>
            </div>
          )}
          {workshopAdditionalInfo.filter(
            (additional) => additional.code === "INFO0001"
          ).length > 0 && (
            <div className="characteristic-container">
              <CkIcon name="24hours" width="" height="" fill="" />
              <p
                className="additional-label"
                style={isMobile ? {} : { width: "79px" }}
              >
                Servicio 24/7
              </p>
            </div>
          )}
        </div>
      </div>
  );
};
export default memo(WorkshopAdditional);
