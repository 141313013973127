import React, { useEffect, useRef, memo } from "react";

import { gtmSetId } from "../../../utilities";

import "./styles.css";

const HowItWorks = () => {
  const iFrameRef = useRef<HTMLIFrameElement | undefined>(undefined);
  var listener: any;

  useEffect(() => {
    listener = window.addEventListener("blur", () => {
      if (document.activeElement === iFrameRef.current) {
        gtmSetId(iFrameRef.current);
      }
    });
    return () => {
      window.removeEventListener("blur", listener);
    };
  }, []);

  return (
    <>
      <section className="how-it-works">
        <h2>¿Qué es CarKer?</h2>
        <p>
          CarKer es la plataforma que te permite <b>encontrar fácilmente el taller mecánico más adecuado y confiable para ti.</b> 
        </p>
        <iframe
          data-gtm-id="QUEES001"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/9WKrj8eYajM?si=xWAbYZnh8BPbzYcy?autoplay=1><img src=https://img.youtube.com/vi/9WKrj8eYajM/hq2.jpg alt='Retoma el control de tu coche con CarKer™'><span><svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#fff" viewBox="0 0 16 16"><path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path></svg></span></a>`}
          ref={iFrameRef}
        />
      </section>
    </>
  );
};

export default memo(HowItWorks);
