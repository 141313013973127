import React, { memo, useEffect, useState } from "react";
import { Image } from "antd";
import LazyLoad from "react-lazyload";
import { getImageBaseUrl } from "../../../utilities";

interface IImage {
  img: {
    name: string;
    description: string;
    photoUrl: string;
  };
  alt: string;
  sasToken: string;
  imgStyles?: any;
  className?: string;
}

const CarKerWatermarkLogo = getImageBaseUrl("carker_watermark.png");
const ImagePlaceholder = getImageBaseUrl("workshop-placeholder.png");

const WatermarkImage: React.FC<IImage> = ({
  img,
  alt,
  sasToken,
  imgStyles,
  className,
}) => {
  const [imgData, setImgData] = useState<string>();
  const [originalUrl, setOriginalUrl] = useState<string>("");

  useEffect(() => {
    const watermakImage = async (originalImage: string) => {
      const img = new window.Image();
      img.src = originalImage;
      img.setAttribute("crossorigin", "anonymous");
      await new Promise((resolve) => (img.onload = resolve));

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const canvasWidth = img.width;
      const canvasHeight = img.height;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // initializing the canvas with the original image
      context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      // loading the watermark image and transforming it into a pattern
      const result = await fetch(CarKerWatermarkLogo);
      const blob = await result.blob();
      const watermarkImage = await createImageBitmap(blob);
      const pattern = context.createPattern(watermarkImage, "repeat");

      context.rect(0, 0, canvasWidth, canvasHeight);
      context.fillStyle = pattern;

      context.fill();

      setImgData(canvas.toDataURL("image/png"));
      return canvas.toDataURL();
    };

    if (img && img.photoUrl !== "") {
      setOriginalUrl(`${img.photoUrl}?${sasToken}`);
      watermakImage(`${img.photoUrl}?${sasToken}`);
    } else {
      setOriginalUrl("placeholder");
      setImgData(ImagePlaceholder);
    }
  }, []);

  return (
    img &&
    imgData && (
      <LazyLoad once>
        <Image
          className={className ? className : ""}
          preview={
            originalUrl === "placeholder"
              ? false
              : {
                  src: imgData,
                }
          }
          style={imgStyles}
          alt={originalUrl === "placeholder" ? "" : `Galería ${alt}`}
          src={originalUrl === "placeholder" ? imgData : originalUrl}
          loading="lazy"
          fallback={ImagePlaceholder}
        />
      </LazyLoad>
    )
  );
};

export default memo(WatermarkImage);
