import React from "react";
import { Link } from "react-router-dom";
import { CkIcon } from "../../../CkUI/components";

import "./styles.css";

const OurWorkshops = () => (
  <section className="our-workshops">
    <div>
      <h2>Más sobre talleres afiliados</h2>
      <p>
        Todos los talleres afiliados dentro de la plataforma han{" "}
        <b>aprobado un estricto proceso de</b> <b>validación</b> de CarKer y{" "}
        <b>aseguran su ética comercial por escrito.</b> Se diferencian por su:
      </p>

      <div className="workshop-characteristic-container">
        <div className="workshop-characteristic">
          <CkIcon name="hand-shake" width="" height="" fill="" />
          <p>Transparencia</p>
        </div>

        <div className="workshop-characteristic">
          <CkIcon name="hand-ok" width="" height="" fill="" />
          <p>Precio justo</p>
        </div>

        <div className="workshop-characteristic">
          <CkIcon name="gear" width="" height="" fill="" />
          <p>Calidad</p>
        </div>

        <div className="workshop-characteristic">
          <CkIcon name="hands" width="" height="" fill="" />
          <p>Inclusión</p>
        </div>

        <div className="workshop-characteristic">
          <CkIcon name="hand-heart" width="" height="" fill="" />
          <p>Respeto a compromisos</p>
        </div>
      </div>

      <Link
        to="/talleres-afiliados"
        className="our-workshops-cta"
        aria-label="Conoce más sobre el proceso de afiliación de los talleres aquí"
      >
        Conoce más sobre el proceso de afiliación de los talleres{" "}
        <span className="semibold">aquí</span>
      </Link>
    </div>
  </section>
);

export default OurWorkshops;
