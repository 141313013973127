import React, { useEffect, useState } from "react";
import { Image } from "antd";
import LazyLoad from "react-lazyload";
import "./styles.css";

const Specialization: React.FC<{ workshopBrands: string[], isWorkshopList?: boolean }> = ({
  workshopBrands,
  isWorkshopList,
}) => {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    let reset = true;
    const fetchImage = async (brand) => {
      try {
        const brandImage = await import(
          `../../../../../assets/Images/BrandsIcons/${brand}.svg`
        );
        setImages((imageList) => [
          ...(reset ? [] : imageList),
          brandImage.default
        ]);
        if (reset) reset = false;
      } catch (err) {
        console.log(err);
      }
    };
    workshopBrands.forEach((brand) => fetchImage(brand));
  }, [workshopBrands]);

  return (
    workshopBrands &&
    workshopBrands.length > 0 && (
      <div className="spec-container">
        <p className={isWorkshopList? "list-label":"label"}>{isWorkshopList? "Especialistas en: ": "Nos especializamos en: "}</p>
        {images.map((brandImage) => (
          <LazyLoad once>
            <Image
              src={brandImage}
              className={isWorkshopList? "list-image" : "image"}
              preview={false}
              style={{ height: "24px", width: "100%", maxWidth: "48px" }}
              alt={`Logo ${brandImage}`}
            />
          </LazyLoad>
        ))}
      </div>
    )
  );
};

Specialization.defaultProps = {
  isWorkshopList: false,
}

export default React.memo(Specialization);
