//React & styles
import React, { FC, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
//Antd components
import { Carousel } from "antd";
import { getImageBaseUrl } from "../../../utilities";
import "./styles.css";
import BannersAPI from "../../../services/axios/BannersAPI";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../stores";

const placeholderDesktop = getImageBaseUrl(
  "LandingCarousel/Banner_Desktop_Placeholder.jpg"
);
const placeholderDesktopWebp = getImageBaseUrl(
  "LandingCarousel/Banner_Desktop_Placeholder.webp"
);
const placeholderMobile = getImageBaseUrl(
  "LandingCarousel/Banner_Mobile_Placeholder.jpg"
);
const placeholderMobileWebp = getImageBaseUrl(
  "LandingCarousel/Banner_Mobile_Placeholder.webp"
);

interface IBanner {
  index: number;
  default?: boolean;
  name: string;
  desktop: {
    jpg: string;
    webp?: string;
  };
  mobile: {
    jpg: string;
    webp?: string;
  };
  link?: string;
}
interface IPromotionalImage {
  promotion: IBanner;
  sasToken?: string;
}

const defaultBanner = {
  default: true,
  index: 1,
  name: `Bienvenido a CarKer`,
  desktop: {
    jpg: placeholderDesktop,
    webp: placeholderDesktopWebp,
  },
  mobile: {
    jpg: placeholderMobile,
    webp: placeholderMobileWebp,
  },
};

const PromotionalImage: FC<IPromotionalImage> = ({ promotion, sasToken }) => {
  const pictures = (
    <LazyLoad once>
      <picture>
        {/* <!-- sources for viewport width greater than 768px --> */}
        {promotion.desktop.webp && (
          <source
            srcSet={`${promotion.desktop.webp}?${sasToken || ""}`}
            type="image/webp"
            media="(min-width: 769px)"
          />
        )}
        <source
          srcSet={`${promotion.desktop.jpg}?${sasToken || ""}`}
          type="image/jpeg"
          media="(min-width: 769px)"
        />
        {/* <!-- sources for viewport width 768px or less --> */}
        {promotion.mobile.webp && (
          <source
            srcSet={`${promotion.mobile.webp}?${sasToken || ""}`}
            type="image/webp"
            media="(max-width: 768px)"
          />
        )}
        <source
          srcSet={`${promotion.mobile.jpg}?${sasToken || ""}`}
          type="image/jpeg"
          media="(max-width: 768px)"
        />
        {/* <!-- fallback image for browsers that don't support the picture element --> */}
        <img
          src={`${promotion.mobile.jpg}?${sasToken || ""}`}
          alt={promotion.name}
          {...(promotion.index > 0 ? { loading: "lazy" } : {})}
        />
      </picture>
    </LazyLoad>
  );

  return (
    <figure className="promotional-image">
      {promotion.link ? (
        <a
          aria-label={promotion.name}
          href={promotion.link}
          rel="noopener noreferrer"
        >
          {pictures}
        </a>
      ) : (
        pictures
      )}
    </figure>
  );
};

interface IProps {}

//Component
const WelcomeCarousel: React.FC<IProps> = () => {
  const sasToken = useSelector(getSasToken);
  const [bannersData, setBannersData] = useState<IBanner[]>([defaultBanner]);

  // State to keep track of which slide is currently active
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to handle slide change
  const handleSlideChange = (current: number) => {
    setCurrentSlide(current);
  };

  const getBanners = async () => {
    const bannersRequest = await BannersAPI.getBanners()
      .then((response) => response.data)
      .catch(() => []);
    if (bannersRequest.length > 0) {
      setBannersData(
        bannersRequest
          .map((banner: any, index: number) => {
            return {
              index: banner.bannerIndex,
              name: `Imagen de bienvenida ${index}`,
              desktop: {
                jpg: banner.bannerUrlImageDesktop,
                ...(!!banner.bannerUrlImageDesktopWebp ? {webp: banner.bannerUrlImageDesktopWebp}:{})
              },
              mobile: {
                jpg: banner.bannerUrlImageMobile,
                ...(!!banner.bannerUrlImageMobileWebp ? {webp: banner.bannerUrlImageMobileWebp}:{})
              },
              ...(!!banner.bannerLinkRedirect
                ? {
                    link: banner.bannerLinkRedirect,
                  }
                : {}),
            };
          })
      );
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <Carousel
      className="simple-promotional-carousel-container disable-scroll-y"
      dotPosition={"bottom"}
      infinite={true}
      autoplay={true}
      autoplaySpeed={5000}
      easing="ease-in-out"
      effect={"scrollx"}
      lazyLoad="progressive"
      afterChange={handleSlideChange}
    >
      {bannersData.map((banner: IBanner, index) => (
        <div>
          {Math.abs(currentSlide - index) <= 1 && (
            <PromotionalImage
              key={`banner-${index}`}
              promotion={banner}
              sasToken={banner?.default ? undefined : sasToken}
            />
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default WelcomeCarousel;
