//React & styles
import React, { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Metadata from "../../components/Metadata";
import WelcomeCarousel from "./WelcomeCarousel";
import WorkshopFinder from "../../components/WorkshopFinder";
import OutstandingWorkshop from "./OutstandingWorkshop";
import HowItWorks from "./HowItWorks";
import OurWorkshops from "./OurWorkshops";
import WhyUseIt from "./WhyUseIt";
import "./styles.css";

//Interfaces
interface IProps {}

//Component
const Home: React.FC<IProps> = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="home-page page-container">
      <Metadata />
      <WelcomeCarousel />
      <WorkshopFinder
        onSearchCallback={() => history.push("/talleres/listado")}
        title="Te conectamos con un taller de confianza"
        id="agenda-tu-cita"
      />
      <OutstandingWorkshop />
      <HowItWorks />
      <WhyUseIt />
      <OurWorkshops />
    </section>
  );
};

export default memo(Home);
